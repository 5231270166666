<template>
	<!-- 查看数据质量信息 -->
	<el-dialog title="查看详细信息" :visible.sync="visible" width="75%">
		<el-card>
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
			 border stripe>
				<el-table-column  prop="name" label="数据项名称" header-align="center" align="center" width="120">
				</el-table-column>
				<el-table-column label="完整性" header-align="center" align="center" width="95">
					<el-table-column prop="isExisted" label="数据是否存在" header-align="center" align="center" width="95">
					</el-table-column>
				</el-table-column>
				<el-table-column label="准确性" header-align="center" align="center" width="540">
					<el-table-column prop="type" label="数据类型" header-align="center" align="center" width="90">
					</el-table-column>
					<el-table-column label="阈值范围" header-align="center" align="center">
						<el-table-column prop="uprange" label="上阈值" header-align="center" align="center" width="90">
						</el-table-column>
						<el-table-column prop="lowrange" label="下阈值" header-align="center" align="center" width="90">
						</el-table-column>
					</el-table-column>
					<el-table-column  label="数据合理范围" header-align="center" align="center">
						<el-table-column prop="rationalityuprange" label="上阈值" header-align="center" align="center"
							width="90">
						</el-table-column>
						<el-table-column prop="rationalitylowrange" label="下阈值" header-align="center" align="center"
							width="90">
						</el-table-column>
					</el-table-column>
					<el-table-column prop="storage_len" label="数据存储长度" header-align="center" align="center" width="90">
					</el-table-column>
				</el-table-column>
				<el-table-column label="时效性" header-align="center" align="center" width="180">
					<el-table-column prop="timedelay" label="时延要求" header-align="center" align="center" width="90">
					</el-table-column>
					<el-table-column prop="timesequence" label="时序性要求" header-align="center" align="center" width="90">
					</el-table-column>
				</el-table-column>
	
			</el-table>
		</el-card>
	</el-dialog>
</template>

<script>
	export default {
		name: "checkSystem",
		data() {
			return {
				visible: false,
				value: "",
				tableData: [{
					id:'',
                    name: '',
                    isExisted: '',
                    type: '',
                    uprange: '',
					rationalitylowrange:'',
                    rationalityuprange: '',
                    unit: '',
                    storage_len: '',
                    timedelay: '',
                    timesequence: '',
                    version: '',
                    versionoptions: [],
					}
				],

				currentPage1: 1,
				currentPage2: 2,
				currentPage3: 3,
				currentPage4: 4,
			};
		},
		methods: {
			init(row) {
				this.tableData[0].name=row[0].name;
				this.tableData[0].storage_len=row[0].storage_len;
				this.tableData[0].isExisted=row[0].isExisted;
				this.tableData[0].lowrange=row[0].lowrange;
				this.tableData[0].uprange=row[0].uprange;
				this.tableData[0].rationalitylowrange=row[0].rationalitylowrange;
				this.tableData[0].rationalityuprange=row[0].rationalityuprange;
				this.tableData[0].timedelay=row[0].timedelay;
				this.tableData[0].timesequence=row[0].timesequence;
				this.tableData[0].type=row[0].type;
				this.visible = true;
			},
		},
	};
</script>


<style scoped>
</style>
