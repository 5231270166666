<template>
  <el-dialog :title="'数据质量标准查看'" :close-on-click-modal="false" :visible.sync="visible">
    <div>
    <el-table :data="dataList" border v-loading="dataListLoading"
      style="width: 100%;">

      <el-table-column  prop="dataQualityName" header-align="center" align="center" label="标准名称">
      </el-table-column>
      <el-table-column prop="dataQualityValue" header-align="center" align="center" label="标准要求值">
      </el-table-column>
      <el-table-column prop="dataGovernMethodName" header-align="center" align="center" label="治理方法">
      </el-table-column>

    </el-table>
    </div>

  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataList:[],
      measurePointId:0,
      dataListLoading:false,
    };
  },
  mounted(){

  },
  methods: {
    /**
     * 初始化信息
     */
    init(id) {
      this.visible = true;
      this.measurePointId=id;
      console.log("数据项ID"+this.measurePointId);
      this.dataListLoading=true;
      this.getRequest(
          "/dataQualityVersion/watchDqInfoByMeasurePointId?measurePointId=" +
          id
        ).then((resp) => {
          if (resp) {
            this.dataList=resp.data.data;
          }
          this.dataListLoading=false;
        });
    },

  },
};
</script>
<style scoped>

</style>
