<template>
  <div>
    <span style="font-size: 16px">搜索：</span>
    <el-select
      style="width: 200px"
      v-model="currentSubEquipnameId"
      placeholder="请选择"
      @change="selectCurrentpoint()"
      v-loading="optionLoading"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <template>
      <el-table
        :data="tableData"
        style="width: 100%"
        @header-click="mpQuality"
        v-loading="dataListLoading"
      >
        <el-table-column
          prop="rowKey"
          label="日期"
          width="150"
        >
          <template slot-scope="scope">
            {{ tableData[scope.$index].rowKey }}
          </template>
        </el-table-column>
        <el-table-column
          prop="properties"
          :label="item.name"
          align="center"
          v-for="(item, index) in tableHeader"
          :key="item.mpId"
        >
          <el-table-column
            label="是否存在问题"
            width="80"
          >
            <template slot-scope="scope">
              <div :style="
                  tableData[scope.$index].Isproblem[index] == '是'
                    ? 'color:#FF0000;'
                    : 'color:#000000;'
                ">
                {{ tableData[scope.$index].Isproblem[index] }}
              </div>

              <!-- {{tableData[scope.$index].properties[index].isProblem}} -->
            </template>
          </el-table-column>
          <el-table-column
            label="治理前"
            width="80"
          >
            <template slot-scope="scope">
              {{ tableData[scope.$index].before[index] }}
              <!-- {{tableData[scope.$index].properties[index].before}} -->
            </template>
          </el-table-column>
          <el-table-column
            label="治理后"
            width="80"
          >
            <template slot-scope="scope">
              {{ tableData[scope.$index].after[index] }}
              <!-- {{tableData[scope.$index].properties[index].after}} -->
            </template>
          </el-table-column>
        </el-table-column>

        <!-- <el-table-column prop="classify" label="所属分类" header-align="center" align="center" width="120">
                      </el-table-column> -->
        <!-- <el-table-column label="数据项值" header-align="center" align="center" width="120">
                          <template slot-scope="scope">
                              <el-button type="text" size="small" @click="pointnum(scope.row)">...</el-button>
                          </template>
                      </el-table-column> -->
        <el-table-column
          label="更多"
          header-align="center"
          align="center"
          width="120"
          v-if="moreVisible"
        >
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--              type="text"-->
<!--              size="small"-->
<!--              @click="more(scope.row)"-->
<!--            >...</el-button>-->
<!--          </template>-->
        </el-table-column>
      </el-table>
    </template>

    <!-- 分页区域 -->
    <el-pagination
      background
      @current-change="currentChange"
      @size-change="sizeChange"
      :current-page="page"
      :page-size="size"
      layout="sizes, prev, pager, next, jumper, ->, total, slot"
      :total="total"
    >
    </el-pagination>
<!--    <detailDia-->
<!--      v-if="detailVisible"-->
<!--      ref="detailDialog"-->
<!--    ></detailDia>-->
    <watch-dq-info v-if="detailVisible" ref="detailDialog"></watch-dq-info>
  </div>
</template>

<script>
import { pinyin } from "pinyin-pro";
//   import {
//         getFullChars
//     } from '@/utils/convertToPinyin'
import detailDia from "./detailDia";
import watchDqInfo from "../../dataQualityPlugin/watchDqInfo"
export default {
  name: "dataDetail_chart",
  data() {
    return {
      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 50, //全部数据量
      visible: false,
      // 当前下拉选框对应的hbase表名
      hbasetablename: "",
      // pointnumVisible:false,
      value: "",
      currentEquipId: 0,
      options: [
        {
          id: 1,
          name: "皮带张力",
        },
        {
          id: 2,
          name: "1#滚筒",
        },
        {
          id: 3,
          name: "2#滚筒",
        },
      ],

      tableData: [
        {
          before: [1, 2, 3],
          after: [4, 5, 6],
        },
      ],

      tableHeader: [],

      databaseType: "",
      database: [
        {
          value: "选项1",
          label: "hbase",
        },
        {
          value: "选项2",
          label: "influxdb",
        },
        {
          value: "选项3",
          label: "mysql",
        },
      ],

      currentPage1: 1,
      currentPage2: 2,
      currentPage3: 3,
      currentPage4: 4,
      detailVisible: false,
      moreVisible: false,
      currentSubEquipnameId: "",
      subEuipname: "",
      currentEquipname: "",
      dataListLoading: true,
      optionLoading: true,

      timer: null,

      //保存后端转拼音结果
      pinyinResult: []
    };
  },
  components: {
    detailDia,
    watchDqInfo
  },

  methods: {
    // 点击表头查看数据项信息
    mpQuality(column, event) {
        if (column.label === "更多") {
            this.size += 10;
            this.initData();
        }
      // console.log(column);
      // console.log(event);
      if (column.level == 1 && column.property == "properties") {
        var columnData = this.tableHeader.filter((item) => {
          return item.name == column.label;
        });
      }
      console.log(columnData);
      this.detailVisible = true;
      console.log(this.tableHeader, column, event)
      // this.$nextTick(() => {
      //   this.$refs.detailDialog.init(columnData);
      // }
      this.$nextTick(() => {
        this.$refs.detailDialog.init(columnData[0].id);
      });
    },

    confirmEnding(str, target) {
      var start = str.length - target.length;
      var arr = str.substr(start, target.length);
      if (arr == target) {
        return true;
      }
      return false;
    },
    getMore(row) {
      // console.log(row);
    },
    // 改变当前附属设备选项
    selectCurrentpoint() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.tableData = [];
      this.tableHeader = [];
      for (let i in this.options) {
        if (this.options[i].id == this.currentSubEquipnameId) {
          this.subEuipname = this.options[i].name;
          this.hbasetablename = this.options[i].hbasetablename;

          //              console.log('this.hbasetablename');
          // console.log(this.options[0]);
          break;
        }
      }
      this.initData();
    },
    currentChange(currentPage) {
      this.page = currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    watch(id) {
      this.detailVisible = true;
      this.$nextTick(() => {
        this.$refs.detailDialog.init(id);
      });
    },
    // more(column, event){
    //   if (column.label === "更多") {
    //       this.size += 10;
    //       this.initData();
    //   }
    // },
    // pointnum(row) {
    //     this.pointnumVisible = true;
    //     this.$nextTick(() => {
    //         this.$refs.pointnumDialog.init(row);
    //     });
    // },

    // 根据传来的设备currentEquipId找到对应的附属设备选项
    getSubEquipOption() {
      this.getRequest(
        "/coalManage/getSubEquipmentInfoByEquipmentId/?equipmentId=" +
          this.currentEquipId
      ).then((resp) => {
        if (resp.data) {
          console.log("coalManage:"+resp.data);
          this.options = resp.data;
          this.currentSubEquipname = this.options[0].name; //将当前子系统与id设置为下拉框第一个
          this.currentSubEquipnameId = this.options[0].id;
          this.optionLoading = false;
          this.subEuipname = this.options[0].name;
          this.hbasetablename = this.options[0].hbasetablename;
          // console.log(this.hbasetablename);
          this.initData();
        }
      });
    },
    // 根据附属设备id拿到对应的数据项，并展示在table中
    initData() {
      // this.dataListLoading = true;

      // this.currentSubEquipnameId = parseInt(this.currentSubEquipnameId);
      console.log("initData-----")
      this.getRequest(
        "/measurePointManage/getMeasurePointInfoBySubEquipmentIdWithPinyin/?subEquipmentId=" +
          this.currentSubEquipnameId +
          "&page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        // this.getRequest('/dataList/getMeasurepointInfoBySubEuipId/?SubEuipId=' + this.currentSubEquipnameId + '&page=' + this.page + '&size=' + this.size).then(resp=>{
        if (resp) {
          this.tableHeader = resp.data.data;
          this.pinyinResult = resp.data.pinyin;
          console.log("this.pinyin");
          console.log(this.pinyinResult);
          this.dataListLoading = false;
          console.log("shoudao")
          console.log(resp.data.data)
          console.log("this.tableHeader");
          console.log(this.tableHeader);
          this.total = resp.data.total;

          //是否显示更多
          if(this.total > this.page * this.size){
              this.moreVisible = true;
          }
          else{
              this.moreVisible = false;
          }
        }
      });
      this.startGetData();
    },
    getHbaseData() {
      this.getRequest(
        "/hbase/getMpInfoByHbaseTablename/?hbasetablename=" +
          this.hbasetablename
      ).then(({ data }) => {
        console.log("this.hbasetablename:"+this.hbasetablename);
        if (data) {
            // let pinyinHeader = this.tableHeader.map((item) => {
            //   return pinyin(item.name, {
            //     toneType: "none",
            //   }).replace(/\s*/g, "");
            // });
            let pinyinHeader = [];
            for(let i=0;i<this.tableHeader.length;i++){
              pinyinHeader[i] = this.pinyinResult[i];
            }
            var arr = [];
            data.forEach((item) => {
              console.log("item",item);
              //item为对象数组中的每一个对象
              let o = {
                before: new Array(pinyinHeader.length).fill(" "),
                after: new Array(pinyinHeader.length).fill(" "),
                Isproblem: new Array(pinyinHeader.length).fill(" "),
              };
              console.log("o"+o);
              console.log("pinyinHeader.length:"+pinyinHeader.length)
              o["rowKey"] = item["date"];
              //对item中的每一个属性值做判断
              for (let key in item) {
                //循环表头中的数据项名称
                for (let i = 0; i < pinyinHeader.length; i++) {
                  //如果是治理前的值，通过splice插入对应位置（保证顺序）
                  if (key.startsWith(pinyinHeader[i]) && key.endsWith("before")) {
                    // o.before.splice(i, 1, eval("item." + key));
                    o.before.splice(i, 1, item[key]);
                  }
                  //治理后同理
                  if (key.startsWith(pinyinHeader[i]) && key.endsWith("after")) {
                    // o.after.splice(i, 1, eval("item." + key));
                    o.after.splice(i, 1, item[key]);
                  }
                }
                //根据治理前后的值判断是否存在问题
                for (let j = 0; j < pinyinHeader.length; j++) {
                  if (o.before[j] != "") {
                    o.Isproblem.splice(
                            j,
                            1,
                            o.before[j] == o.after[j] ? "否" : "是"
                    );
                  }
                }
              }
              //将整理后的对象插入数组中
              arr.push(o);
            });
            console.log("arr");
            console.log(arr);
            this.tableData = arr;
          // })

        }
      });
    },
    startGetData() {
      this.getRequest(
          "/hbase/isHbaseTableExists/?hbaseTableName=" +
          this.hbasetablename
      ).then((resp) => {
        if (resp.data.msg === "success") {
                this.getRequest(
                       "/hbase/isHbaseTableExists/?hbaseTableName=" +
                        this.hbasetablename
                   ).then((resp) => {
                    if (resp.data.msg === "success") {
                       this.timer = setInterval(() => {
                            this.getHbaseData();
                          }, 3000);
                      }
                 })
              }
      })
    }
  },
  mounted() {
    this.currentEquipId = this.$route.query.equipmentId;
    // this.currentEquipId = window.sessionStorage.getItem("dataList_currentEquipId");
    // 获取附属设备选项
    this.getSubEquipOption();
    // this.currentSubEquipname = this.options[0].name; //将当前子系统与id设置为下拉框第一个
    // this.currentSubEquipnameId = this.options[0].id;

    // this.initData()
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    console.log("chart kill");
  },
};
</script>

<style scoped></style>
>
